import React, { useState, useEffect, useContext, Suspense } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import { getQS, saveAsFile } from '../../components/utilities/Utils';
import {useHistory} from 'react-router-dom'
import { USBGrid, USBColumn, USBSelect } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';

const FindByRequirementId = () => {

    const [loadinger, setLoading] = useState(false);
    const [requirementId, setRequirementId] = useState("");
    const history = useHistory();

 
    const requirementDetailApiCall = () => {
      const apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/documents/${requirementId}/details`;
      if (requirementId) {
        callApi
          .getApiDataRequirement(apiURL)
          .then((result) => {
            console.log(result.data);
            if (result?.data?.category === 'Closing Binder') {
              history.push(`/ClosingBinders/Portfolio/Requirements?id=${requirementId}`);
            } else if (result?.data?.category?.toLowerCase() === 'invoice') {
              history.push(`/Inbox/${requirementId}`);
            } else if (result?.data?.category?.length > 0) {
              history.push(`/RequirementDetails?id=${requirementId}`);
            } else {
              alert('This Requirement Id is not found. Please search with valid Requirement Id.');
            }
          })
          .catch((error) => {
            console.error('Error fetching requirement data:', error);
          });
      }
    };
    useEffect(() => {
      setRequirementId(getQS('id')||"");
    }, []);
    useEffect(() => {
 
      requirementDetailApiCall();
    }, [requirementId, history]);

    return (
        <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            data-testid="breadcrumb"
            text="Reports"
            breadcrumbs={[
              { id: 1, text: 'Home', href: '/Redirect' },
              { id: 1, text: 'Find By Requirement Id', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid  data-testid="main" addClasses="content-frame content-bg">

        <USBColumn largeSpan={16}>

         
          <div className="sorting-inputs">
           
            <div className="filter-dropdowns-lookup searchInputValue">
              <label>
               Enter Requirement Id : <input
                  type="text"
                  data-testid="main" 
                  name="name"
                  id="searchInput"
                  size={40}
                  value={requirementId}
                  onChange={(e) => {
                    setRequirementId(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="filter-dropdowns-lookup searchButton">
              <USBButton
                type="button"
                variant="primary"
                size="small"
                id="searchButton"
                name="searchButton"
                disabled={false}
                handleClick={() => {
                  requirementDetailApiCall();
                }}
              >
                Search
              </USBButton>
            </div>
           <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
        </div>
        </USBColumn>
      </USBGrid>
    </>

    )
}

export default FindByRequirementId
